import { Pipe, PipeTransform } from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  name: 'dateAgo'
})
export class DateAgoPipe implements PipeTransform {

  constructor(private translate: TranslateService) {
  }

  transform(value: any): string {
      // @ts-ignore
      const seconds = Math.floor((Date.now() - new Date(value)) / 1000);

      let interval = seconds / 31536000;

      if (interval > 1) {
          return Math.floor(interval) + ' ' + this.translate.instant('GLOBAL.YEARS.TEXT');
      }
      interval = seconds / 2592000;
      if (interval > 1) {
          return Math.floor(interval) + ' ' + this.translate.instant('GLOBAL.MONTHS.TEXT');
      }
      interval = seconds / 86400;
      if (interval > 1) {
          return Math.floor(interval) + ' ' + this.translate.instant('GLOBAL.DAYS.TEXT');
      }
      interval = seconds / 3600;
      if (interval > 1) {
          return Math.floor(interval) + ' ' + this.translate.instant('GLOBAL.HOURS.TEXT');
      }
      interval = seconds / 60;
      if (interval > 1) {
          return Math.floor(interval) + ' ' + this.translate.instant('GLOBAL.MINUTES.TEXT');
      }
      return Math.floor(seconds) + ' ' + this.translate.instant('GLOBAL.SECONDS.TEXT');
  }

}
