import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import {catchError} from 'rxjs/operators';
import {AuthService} from '../../shared/service/auth.service';
import {Router} from '@angular/router';
import {LocalStorage} from '../../shared/storage/storage.local';
import jwt_decode from 'jwt-decode';
import {User} from '../../Model/user';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthService,
    private localStorage: LocalStorage,
    private router: Router) {}

  // intercept(
  //   request: HttpRequest<any>,
  //   next: HttpHandler
  // ): Observable<HttpEvent<any>> {
  //   return next.handle(request).pipe(
  //     catchError((err) => {
  //       if (err.status === 401) {
  //         this.authenticationService.currentUserSubject.next(null);
  //         this.authenticationService.currectResponseSubject.next(null);
  //         this.localStorage.destroy('currentUser');
  //         this.localStorage.destroy('response');
  //         location.reload(true);
  //         this.router.navigate(['/authentication/signin']);
  //       }
  //       const error = err.error.message || err.statusText;
  //       return throwError(error);
  //     })

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any>
  {
    return next.handle(req).pipe(
        catchError((err) => {
          if (err.status === 401) {
            const response = this.localStorage.get('response');
            this.authenticationService.post('/token/refresh', {refresh_token: response.refresh_token}, {}).subscribe(
              {
                next: data => {
                  console.log(data);
                  req = req.clone({
                    setHeaders: {
                      Authorization: `Bearer ${data.token}`,
                    }
                  });
                  console.log(req);
                  const user = jwt_decode(data.token) as User;
                  user.token = data.token;
                  this.authenticationService.currentUserSubject.next(user);
                  this.authenticationService.currectResponseSubject.next(data);
                  this.localStorage.post('currentUser', user);
                  this.localStorage.post('response', data);
                  location.reload(true);
                  return next.handle(req);
                }
              }
            );
          }
          return throwError(err);
        }));
  }

}
