
/**
 * An insternal class which implements the IStorage interface using the SubStorage implementation
 * object and it's implementation of the info object.
 *
 * @export
 * @abstract
 * @class AbstractBrowserStorage
 * @extends {SubStorage}
 */
export abstract class AbstractBrowserStorage {
  private cache: {} = null;

  constructor(private storage: Storage) {}

  // get items
  get(key: string): any {
    return JSON.parse(this.storage.getItem(key));
  }

  // add a new item
  post(key: string, data): any {
    return this.storage.setItem(key, JSON.stringify(data));
  }

  // update an item
  put(key: string, data): boolean {
    if (this.check(key)) {
      this.storage.setItem(key, JSON.stringify(data));
      return true;
    }
    return false;
  }

  // remove an item
  destroy(key: string): boolean {
    if (this.check(key)) {
      this.storage.removeItem(key);
      return true;
    }
    return false;
  }

  // Clear Storage
  clear(): void {
    this.storage.clear();
  }

  // Check if item exist
  check(key: string): boolean {
    return !!this.storage.getItem(key);
  }
}
