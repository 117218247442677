import {MatPaginatorIntl} from '@angular/material/paginator';
import {TranslateService} from '@ngx-translate/core';

export class PaginatorI18n {

  constructor(private translate: TranslateService) {}

  getPaginatorIntl(): MatPaginatorIntl {
    const paginatorIntl = new MatPaginatorIntl();
    this.translate.get('PAGINATOR.ITEMS_PER_PAGE_LABEL.TEXT').subscribe((translated: string) => {
      paginatorIntl.itemsPerPageLabel = this.translate.instant('PAGINATOR.ITEMS_PER_PAGE_LABEL.TEXT');
      paginatorIntl.nextPageLabel = this.translate.instant('PAGINATOR.NEXT_PAGE_LABEL.TEXT');
      paginatorIntl.previousPageLabel = this.translate.instant('PAGINATOR.PREVIOUS_PAGE_label.TEXT');
      paginatorIntl.getRangeLabel = this.getRangeLabel.bind(this);
    });
    return paginatorIntl;

  }

  private getRangeLabel(page: number, pageSize: number, length: number): string {
    if (length === 0 || pageSize === 0) {
      return this.translate.instant('PAGINATOR.RANGE_PAGE_LABEL_1.TEXT', { length });
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    // If the start index exceeds the list length, do not try and fix the end index to the end.
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    return this.translate.instant('PAGINATOR.RANGE_PAGE_LABEL_2.TEXT', { startIndex: startIndex + 1, endIndex, length });
  }
}
