import { Component, OnInit } from '@angular/core';
import {LocalStorage} from '../../../shared/storage/storage.local';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: [],
})
export class MainLayoutComponent implements OnInit {
  direction: any;
  constructor(
    private localStorage: LocalStorage
  ) {}

  ngOnInit(): void {
    (this.localStorage.get('isRtl') === true) ? this.direction = 'rtl' : this.direction = 'ltr';
  }
}
