import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {DateAgoPipe} from './date-ago.pipe';
import {SafeHtmlPipe} from './safe-html.pipe';
import { NumberWordsEnPipe } from './number-words-en.pipe';
import { NumberWordsArPipe } from './number-words-ar.pipe';

@NgModule({
  declarations: [DateAgoPipe, SafeHtmlPipe, NumberWordsEnPipe, NumberWordsArPipe],
  exports: [DateAgoPipe, SafeHtmlPipe, NumberWordsEnPipe, NumberWordsArPipe],
  imports: [
    CommonModule
  ]
})
export class AppPipesModule { }
