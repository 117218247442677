export const AppConfig = Object.freeze({
  API_URL: 'https://medadkg.qbox-api.com/api/v1',
  // API_URL: 'http://127.0.0.1:8000/api/v1',
  MEDIA_URL: 'http://3.139.71.32:8000',
  MERCURE_URL: 'https://medadkg.qbox-api.com/hub/mercure',
  // MERCURE_URL: 'http:localhost:3000/.well-known/mercure',
  MERCURE_TOPIC: 'http://qbox.com/notification/',
  // API_URL: 'http://127.0.0.1:8000/api/v1'
  // ###### VIMEO CONFIG  ######
  VIMEO_API_URL: 'https://api.vimeo.com/me/videos',

  MODULE_RH: false,

  BEHAVIORTYPE: {
    physicalAggressive: 'السلوك العدواني البدني',
    verbalAggressive: 'السلوك العدواني اللفظي',
    riotingChaos: 'الشغب و إثارة الفوضى',
    stealing: 'السرقة',
    damageEnvironment: 'الاضرار بالبيئة الصفية او اتلاف ممتلكات الأكاديمية والاخرين',
    lackOFRespect: 'عدم احترام المعلمات أو الإساءة بالقول أو الفعل',
    againstPublicMorals: 'سلوك مناف للآداب العامة بالقول أو الفعل',
  },

  POSITIVE_BEHAVIOR_AR: {
    maintainPresence: 'المواظبة على الحضور للمدرسة',
    commitmentWorkingTime: 'الالتزام بوقت الدوام',
    maintainSchoolProperty: 'الحفاظ على ممتلكات المدرسة',
    preservePersonalItems: 'الحفاظ على الكتب المدرسية والادوات الشخصية',
    listenTeacher: 'الاستماع لتوجيهات المعلمة ',
    askPermissionSpeaking: 'الاستئذان من المعلمة في حال التحدث أو الخروج ',
    sitProperly: 'الجلوس جلسة صحيحة داخل الصف',
    doHomework: 'حل الواجبات',
    collaborateColleagues: 'التعاون مع الزملاء',
    respectEveryone: 'إحترام الجميع',
    other: '---خيار آخر---'
  },
  POSITIVE_BEHAVIOR_EN: {
    maintainPresence: 'Maintain Presence',
    commitmentWorkingTime: 'Commitment Working Time',
    maintainSchoolProperty: 'Maintain School Property',
    preservePersonalItems: 'Preserve Personal Items',
    listenTeacher: 'Listen Teacher',
    askPermissionSpeaking: 'Ask Permission Speaking',
    sitProperly: 'Sit Properly',
    doHomework: 'Do Homework',
    collaborateColleagues: 'Collaborate Colleagues',
    respectEveryone: 'Respect Everyone',
    other: '----other----'
  },
  NEGATIVE_BEHAVIOR_AR: {
    beLate: 'التأخير عن الحضور فيالوقت المحدد لبدء الحصة الدراسية',
    leaveWithoutPermission: ' الخروج من الفصل أو الدخول إليه دون استئذان',
    createChaos: 'إثارة الفوضى داخل الفصل والمدرسة  (العبث بالماء –الصوت العالي-العبث بالأجهزة –الحديث-الحديث الجانبي مع الزملاء)',
    eatFoodWithoutPermission: 'تناول الأطعمة والمشروبات أثناء الدرس دون استئذان',
    interruptExplanationTeacher: 'المقاطعة المستمرة غير الهادفة لشرح المعلم',
    sleepInClass: 'النوم داخل الفصل',
    NotDoHomework: 'عدم أداء الواجبات المدرسية',
    notBringBooks: 'عدم إحضار الكتب أو مستلزمات المواد الدراسية',
    beLateQueue: 'التأخر الصباحي / التأخر عن الطابور الصباحي',
    notParticipateQueue: 'عدم المشاركة في الطابور الصباحي',
    damageSources: 'إتلاف المصادر',
    escapeClasses: 'الهروب من الحصص الدراسية',
    bringMobile: 'إحضار الهواتف النقالة',
    minorQuarrels: 'المشاجرات البسيطة أو التشابك بالأيدي بدون وقوع ضرر',
    arguinWithCollegues: 'الشجار مع الزملاء بالتلفظ بألفاظ غير لائقة',
    damageClassroom: 'الإضرار بالبيئة الصفية والمدرسية',
    notAdhereSchoolUniform: 'عدم االلتزام بالزي المدرسي',
    notPayAttentionAppearance: 'عدم الإهتمام بالمظهر العام',
    notComplianceBusRules: 'عدم الاتزام بقواعد ونظم الحافلات',
    badUseInternet: 'الاستخدام السيء للأنترنت',
    damageSchoolProperty: 'إتلاف وسرقة ممتلكات المدرسة والآخرين',
    improperMannersTeachers: 'إساءة الأدب مع المعلمين/ الإداريين/ العاملين بالقول أو الإشارة بحركات مخلة بالأداب',
    refuseAdministrativeProcedure: 'رفض الطالب للإجراءات الإدارية الصادرة بحقه',
    useDangerousMaterials: 'إحضار واستخدام المواد أو األلعاب الخطرة داخل المدرسة (الألعاب النارية – البخاخات الغازية الملونة وغيره)',
    fightWithHands: 'المشاجرات بالأيدي مع وقوع الضرر',
    badMannersWithBeating: 'إساءة الأدب مع المعلمين / الإداريين / العاملين بالضرب',
    bullying: 'التجمع بهدف إيذاء االخرين ( التنمر)',
    useSharpObjects: 'حيازة أو استخدام أي مواد حادة(السكاكين وغيرها)',
    bringIncedentMaterials: 'إحضار ونشر مواد مخلة بالآداب',
    badUseMobile: 'الإستخدام السئ للهاتف النقال',
    immitateDifferentSex: 'التشبه بجنس مغاير (الجنس الآخر)',
    SexualHarassment: 'التحرشات الجنسية',
    other: '---خيار آخر---',
  },
  NEGATIVE_BEHAVIOR_EN: {
    beLate: 'Be Late',
    leaveWithoutPermission: 'Leave Without Permission',
    createChaos: 'Create Chaos',
    eatFoodWithoutPermission: 'Eat Food Without Permission',
    interruptExplanationTeacher: 'Interrupt Explanation Teacher',
    sleepInClass: 'Sleep In Class',
    NotDoHomework: 'Not Do Homework',
    notBringBooks: 'not Bring Books',
    beLateQueue: 'be Late Queue',
    notParticipateQueue: 'not Participate Queue',
    damageSources: 'damage Sources',
    escapeClasses: 'escape Classes',
    bringMobile: 'bring Mobile',
    minorQuarrels: 'minor Quarrels',
    arguinWithCollegues: 'arguin With Collegues',
    damageClassroom: 'damage Classroom',
    notAdhereSchoolUniform: 'not Adhere School Uniform',
    notPayAttentionAppearance: 'not Pay Attention Appearance',
    notComplianceBusRules: 'not Compliance Bus Rules',
    badUseInternet: 'bad Use Internet',
    damageSchoolProperty: 'damage School Property',
    improperMannersTeachers: 'improper Manners Teachers',
    refuseAdministrativeProcedure: 'Refuse Administrative Procedure',
    useDangerousMaterials: 'Use Dangerous Materials',
    fightWithHands: 'Fight With Hands',
    badMannersWithBeating: 'Bad Manners With Beating',
    bullying: 'Bullying',
    useSharpObjects: 'Use Sharp Objects',
    bringIncedentMaterials: 'Bring Incedent Materials',
    badUseMobile: 'Bad Use Mobile',
    immitateDifferentSex: 'Immitate Different Sex',
    SexualHarassment: 'Sexual Harassment',
    other: '---other---',
  },
  POSITIVE_BEHAVIOR_ACTION_AR: {
    praiseFrontColleagues: 'الثناء على الطالب أمام زملائه',
    encourageContinuePositiveBehavior: 'تشجيعه على الإستمرار في السلوك الإيجابي',
    givePoints: 'منح الطالب نقاط على السلوك الإيجابي',
    issueCertificate: 'إصدار شهادات تقدير وشكر على السلوكيات الإيجابية',
    mentionNameOnRadio: 'ذكر اسمه في الإذاعة الصباحية',
    registerNameOnHonorBoard: ' تسجيل اسمه في لوحة الشرف',
    motivateStudentByAdministration: 'تحفيز الطالب من قبل الإدارة',
    postOnSocialMedia: 'نشر صوره والإشادة به على مواقع التواصل الإجتماعي الخاصة بالأكاديمية',
    other: '---خيار آخر---'
  },
  POSITIVE_BEHAVIOR_ACTION_EN: {
    praiseFrontColleagues: 'praise Front Colleagues',
    encourageContinuePositiveBehavior: 'encourage Continue Positive Behavior',
    givePoints: 'give Points',
    issueCertificate: 'issue Certificate',
    mentionNameOnRadio: 'mention Name On Radio',
    registerNameOnHonorBoard: ' register Name On Honor Board',
    motivateStudentByAdministration: 'motivate Student By Administration',
    postOnSocialMedia: 'post On Social Media',
    other: '----other----'
  },
  NEGATIVE_BEHAVIOR_ACTION_AR: {
    firstVerbalWarning: 'تنبيه شفهي (في المرة االولى)',
    secondVerbalWarning: 'تنبيه شفهي(في المرة الثانية)',
    thirdVerbalWarning: 'تنبيه شفهي (في المرة الثالثة)',
    transferToAdministration: 'تحويل الطالب إلى الإدارة لإتخاذ الإجراءات اللازمة',
    preventIncreasePoints: 'منع زيادة نقاط التعزيز حتى يحسن سلوك الطالب',
    assignAdditionalAssignement: 'تكليف الطالب بمهمة إضافية',
    depriveFromPlaying: 'حرمان الطالب من اللعب أو حصص التربية البدنية – الحاسب الآلي',
    takeWrittenCommitment: 'أخذ تعهد خطي على الطالب',
    summonParent: 'استدعاء ولي الأمر وامضاءه على تعهد خطي',
    other: '---خيار آخر---'
  },
  NEGATIVE_BEHAVIOR_ACTION_EN: {
    firstVerbalWarning: 'first Verbal Warning',
    secondVerbalWarning: 'second Verbal Warning',
    thirdVerbalWarning: 'third Verbal Warning',
    transferToAdministration: 'transfer To Administration',
    preventIncreasePoints: 'prevent Increase Points',
    assignAdditionalAssignement: 'assign Additional Assignement',
    depriveFromPlaying: 'deprive From Playing',
    takeWrittenCommitment: 'take Written Commitment',
    summonParent: 'summon Parent',
    other: '---other---'
  },
  REPORT_BEHAVIOR_HOMEWORK_AR: {
    completeAssignement: 'إكمال الواجب',
    studentPerformanceLevel: 'مستوى أداء الطالب',
    followUp: 'المتابعة',
    studentNotebook: 'دفتر الطالب وكتبه',
  },
  REPORT_BEHAVIOR_HOMEWORK_EN: {
    completeAssignement: 'Completing homework',
    studentPerformanceLevel: 'Homework response',
    followUp: 'Follow up with homework',
    studentNotebook: 'Notebooks and books',
  },
  REPORT_BEHAVIOR_BEHAVIOR_AR: {
    organizeTool: 'يمارس التنظيم والترتيب لأدواته',
    correctHisBehavior: 'يتدرب على تقويم سلوكه',
    controlHisBehavior: 'يعمل على ضبط سلوكه والسيطرة على إنفعالاته',
    haveResponsibility: 'يتحمل بعض المسؤوليات المناسبة لعمره',
    observeSafety: 'يراعي تعليمات الأمان والسلامة',
  },
  REPORT_BEHAVIOR_BEHAVIOR_EN: {
    organizeTool: 'Organizing his/her tools',
    correctHisBehavior: 'The student is improuving his/her behaviour',
    controlHisBehavior: 'Student`/s behaviour under control',
    haveResponsibility: 'The student is responsible',
    observeSafety: 'Follow the safety instructions',
  },
  REPORT_BEHAVIOR_INTERACT_AR: {
    getReady: 'الاستعداد',
    interactInClass: 'التفاعل في الحصة فردي أو مع الزملاء',
    repeateSharing: 'تكرار المشاركة في الصف',
    qualityAnswers: 'جودة الإجابات والمشاركة',
  },
  REPORT_BEHAVIOR_INTERACT_EN: {
    getReady: 'Always ready to participate',
    interactInClass: 'The students participates individually/group',
    repeateSharing: 'Regularly participates',
    qualityAnswers: 'The quality of response',
  },
  ABSENCETYPE: {
    present: 'حاضر',
    absent: 'غائب',
    retard: 'متأخر',
  }
});
