import { Injectable } from '@angular/core';
import {ResourceService} from './resource.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class MessageService extends ResourceService {

  constructor(public httpClient: HttpClient) {
    super(httpClient, '/message');
  }
}
