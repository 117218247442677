import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from '../service/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const currentUser = this.authService.currentUserValue;
    if (currentUser) {
      const roles = currentUser.roles[0];
      const authority = this.authorityToPermission(currentUser.authorities);
      const arrayAuthority = [...roles, ...authority];
      let result = false;
      console.log(route.data.role);
      if (route.data.role) {
        route.data.role.forEach(
          item => {
            if (arrayAuthority.includes(item)) {
              result = true;
            }
          }
        );
        if (result) {
          return true;
        } else {
          this.router.navigate(['/authentication/signin']);
          return false;
        }
    } else {
        this.router.navigate(['/authentication/signin']);
        return false;
      }
    }
  }

  authorityToPermission(authorities): any {
    const auth = [];
    // tslint:disable-next-line:forin
    for (const key in authorities) {
      // tslint:disable-next-line:forin
      for (const authority in authorities[key]) {
        if (authorities[key][authority]) {
          const str = key.toUpperCase() + '_' + authorities[key][authority].toUpperCase();
          auth.push(str);
        }
      }
    }
    return auth;
  }
}
