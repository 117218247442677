import { Injectable } from '@angular/core';
import {ResourceService} from './resource.service';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {LocalStorage} from '../storage/storage.local';

@Injectable({
  providedIn: 'root'
})
export class AuthService extends ResourceService {

  public currentUserSubject: BehaviorSubject<any>;
  public currentUser: Observable<any>;

  public currectResponseSubject: BehaviorSubject<any>;
  public currentResponse: Observable<any>;

  public userRolesSubject: BehaviorSubject<any>;
  public userRoles: Observable<any>;

  constructor(public httpClient: HttpClient,
              private localStorage: LocalStorage) {
    super(httpClient, '');
    this.currentUserSubject = new BehaviorSubject<any>(this.localStorage.get('currentUser'));
    this.currentUser = this.currentUserSubject.asObservable();
    this.currectResponseSubject = new BehaviorSubject<any>(this.localStorage.get('response'));
    this.currentResponse = this.currectResponseSubject.asObservable();

    this.userRolesSubject = new BehaviorSubject<any>('');
    this.userRoles = this.userRolesSubject.asObservable();
  }

  // @ts-ignore
  public get currentUserValue(): any {
    return this.currentUserSubject.value;
  }

  // @ts-ignore
  public get currentUserVae(): any {
    return this.currentUserSubject.value;
  }

  // @ts-ignore
  public get currentResponseValue(): any {
    return this.currectResponseSubject.value;
  }
}
