import { Router, NavigationEnd } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  HostListener,
  OnDestroy,
} from '@angular/core';
import { ROUTES, ROUTESRH } from './sidebar-items';
import { Role } from 'src/app/core/models/role';
import {AuthService} from '../../shared/service/auth.service';
import {LocalStorage} from '../../shared/storage/storage.local';
import {User} from '../../Model/user';
import {NgxPermissionsService} from 'ngx-permissions';
import {SseService} from '../../shared/service/sse.service';
import {tap} from 'rxjs/operators';
import {AppConfig} from '../../app.config';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass'],
})
export class SidebarComponent implements OnInit, OnDestroy {
  public sidebarItems: any[];
  level1Menu = '';
  level2Menu = '';
  level3Menu = '';
  public innerHeight: any;
  public bodyTag: any;
  listMaxHeight: string;
  listMaxWidth: string;
  userFullName: string;
  userImg: string;
  userType: string;
  headerHeight = 60;
  currentRoute: string;
  routerObj = null;
  user: User;
  mercureUrl = AppConfig.MERCURE_URL;
  mercureTopic = AppConfig.MERCURE_TOPIC;
  menuItem: any;
  permission: any;
  direction: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private authService: AuthService,
    private router: Router,
    private sseService: SseService,
    private permissionsService: NgxPermissionsService,
    private localStorage: LocalStorage
  ) {
    this.direction = this.localStorage.get('isRtl');
    const body = this.elementRef.nativeElement.closest('body');
    this.routerObj = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // logic for select active menu in dropdown
        const role = ['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_TEACHER', 'ROLE_STUDENT', 'ROLE_PARENT'];
        const currenturl = event.url.split('?')[0];
        const firstString = currenturl.split('/').slice(1)[0];

        if (role.indexOf(firstString) !== -1) {
          this.level1Menu = currenturl.split('/')[2];
          this.level2Menu = currenturl.split('/')[3];
        } else {
          this.level1Menu = currenturl.split('/')[1];
          this.level2Menu = currenturl.split('/')[2];
        }
        // close sidebar on mobile screen after menu select
        this.renderer.removeClass(this.document.body, 'overlay-open');
      }
    });
  }

  @HostListener('window:resize', ['$event'])
  windowResizecall(event) {
    this.setMenuHeight();
    this.checkStatuForResize(false);
  }
  @HostListener('document:mousedown', ['$event'])
  onGlobalClick(event): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.renderer.removeClass(this.document.body, 'overlay-open');
    }
  }

  callLevel1Toggle(event: any, element: any) {
    if (element === this.level1Menu) {
      this.level1Menu = '0';
    } else {
      this.level1Menu = element;
    }
    const hasClass = event.target.classList.contains('toggled');
    if (hasClass) {
      this.renderer.removeClass(event.target, 'toggled');
    } else {
      this.renderer.addClass(event.target, 'toggled');
    }
  }

  callLevel2Toggle(event: any, element: any) {
    if (element === this.level2Menu) {
      this.level2Menu = '0';
    } else {
      this.level2Menu = element;
    }
  }

  callLevel3Toggle(event: any, element: any) {
    if (element === this.level3Menu) {
      this.level3Menu = '0';
    } else {
      this.level3Menu = element;
    }
  }

  ngOnInit() {
    this.authService.currentUser.subscribe( data => {
      if (data) {
        const userRole = data.roles;
        this.permissionsService.flushPermissions();
        if (this.localStorage.get('role') === 'ROLE_AGENT') {
          this.permission = this.authorityToPermission(data.authorities);
          this.permission.unshift(this.localStorage.get('role'));
          this.permissionsService.loadPermissions(this.permission);
        } else {
          this.permissionsService.loadPermissions([this.localStorage.get('role')]);
          this.permission = [this.localStorage.get('role')];
        }

        // this.sidebarItems = ROUTES.filter(
        //   (x) => x.role.includes(this.localStorage.get('role')) !== false || x.role.includes('All') !== false);

        if (this.localStorage.get('module') === 'RH') {
          this.sidebarItems = ROUTESRH.filter(
            (x) => x.role.some(r => this.permission.indexOf(r) >= 0) || x.role.includes('All') !== false
          );
        } else if (this.localStorage.get('module') === 'EDUCATION') {
          this.sidebarItems = ROUTES.filter(
            (x) => x.role.some(r => this.permission.indexOf(r) >= 0) || x.role.includes('All') !== false
          );
        }

        // this.sidebarItems = ROUTES.filter(
        //   (x) => x.role.some(r => this.permission.indexOf(r) >= 0) || x.role.includes('All') !== false
        // );

        this.sidebarItems.forEach(item => {
          item.submenu = item.submenu.filter(
            (m) => m.role.some(res => this.permission.indexOf(res) >= 0) || m.role.includes('All') !== false
          );
        });

        // this.sidebarItems = ROUTES.filter(
        //   (x) => x.role.some(r => this.permission.indexOf(r) >= 0) || x.role.includes('All') !== false
        // );

        this.notifMercure(data.id).subscribe();
        // const url = new URL('http://3.139.71.32:3000/.well-known/mercure/ui');
        // url.searchParams.append('topic', 'http://qbox.com/check/' + `${data.id}`);
        // this.sseService.getServerSentEvent(url).pipe(
        //   tap( (event) => {
        //     console.log(event);
        //   })
        // );
        if (this.direction === true) {
          this.userFullName =
            data.firstNameAr +
            ' ' +
            data.lastNameAr;
        } else {
          this.userFullName =
            data.firstName +
            ' ' +
            data.lastName;
        }

        if (data.avatar === null) {
          this.userImg = 'assets/images/qbox/avatar-man.png';
        } else {
          this.userImg = data.avatar;
        }

        // userRole[0].forEach(r => {
        //   this.sidebarItems = ROUTES.filter(
        //     (x) => x.role.includes(r) !== false || x.role.includes('All') !== false);
        // });

        // if (userRole === Role.Admin) {
        //   this.userType = Role.Admin;
        // } else if (userRole === Role.Teacher) {
        //   this.userType = Role.Teacher;
        // } else if (userRole === Role.Student) {
        //   this.userType = Role.Student;
        // } else {
        //   this.userType = Role.Admin;
        // }
      }
    });

    this.initLeftSidebar();
    this.bodyTag = this.document.body;
  }

  authorityToPermission(authorities): any {
    const auth = [];
    // tslint:disable-next-line:forin
    for (const key in authorities) {
      // tslint:disable-next-line:forin
      for (const authority in authorities[key]) {
        if (authorities[key][authority]) {
          const str = key.toUpperCase() + '_' + authorities[key][authority].toUpperCase();
          auth.push(str);
        }
      }
    }
    return auth;
  }

  ngOnDestroy() {
    this.routerObj.unsubscribe();
  }

  initLeftSidebar() {
    const _this = this;
    // Set menu height
    _this.setMenuHeight();
    _this.checkStatuForResize(true);
  }

  setMenuHeight() {
    this.innerHeight = window.innerHeight;
    const height = this.innerHeight - this.headerHeight;
    this.listMaxHeight = height + '';
    this.listMaxWidth = '500px';
  }

  isOpen() {
    return this.bodyTag.classList.contains('overlay-open');
  }

  checkStatuForResize(firstTime) {
    if (window.innerWidth < 1170) {
      this.renderer.addClass(this.document.body, 'ls-closed');
    } else {
      this.renderer.removeClass(this.document.body, 'ls-closed');
    }
  }

  mouseHover(e) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('submenu-closed')) {
      this.renderer.addClass(this.document.body, 'side-closed-hover');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    }
  }

  mouseOut(e) {
    const body = this.elementRef.nativeElement.closest('body');
    if (body.classList.contains('side-closed-hover')) {
      this.renderer.removeClass(this.document.body, 'side-closed-hover');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }

  logout() {
    this.authService.currentUserSubject.next(null);
    this.localStorage.destroy('currentUser');
    this.localStorage.destroy('role');
    this.router.navigate(['/auth/login']);
  }

  notifMercure(userId) {
    const url = new URL(this.mercureUrl);
    url.searchParams.append('topic', this.mercureTopic + `${userId}`);
    return this.sseService.getServerSentEvent(url).pipe(
      tap( (event) => {
      })
    );
  }
}
