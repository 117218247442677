import {Injectable, NgZone} from '@angular/core';
import {Observable} from 'rxjs';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SseService {
  public data: Observable<any>;
  public dataSubject: BehaviorSubject<any>;

  constructor(private zone: NgZone) {
    this.dataSubject = new BehaviorSubject<any>(null);
    this.data = this.dataSubject.asObservable();
  }

  private getEventSource(url: string): EventSource {
    return new EventSource(url);
  }

  getServerSentEvent(url): Observable<MessageEvent> {
    return new Observable(observer => {
      const eventSource = this.getEventSource(url);

      eventSource.onmessage = event => {
        this.dataSubject.next(JSON.parse(event.data));
        this.zone.run(() => observer.next(event));
      };
    });
  }
}
