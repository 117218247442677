import { ConfigService } from '../../config/config.service';
import { DOCUMENT } from '@angular/common';
import {
  Component,
  Inject,
  ElementRef,
  OnInit,
  Renderer2,
  AfterViewInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { RightSidebarService } from 'src/app/core/service/rightsidebar.service';
import { LanguageService } from 'src/app/core/service/language.service';
import {AuthService} from '../../shared/service/auth.service';
import {LocalStorage} from '../../shared/storage/storage.local';
import {NgxSpinnerService} from 'ngx-spinner';
import {SseService} from '../../shared/service/sse.service';
import {NotificationService} from '../../shared/service/notification.service';
import {HttpParams} from '@angular/common/http';
import {NgxPermissionsService} from 'ngx-permissions';
import {MessageService} from '../../shared/service/message.service';
import {AppConfig} from "../../app.config";
const document: any = window.document;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
})
export class HeaderComponent implements OnInit, AfterViewInit {
  public config: any = {};
  userImg: string;
  homePage: string;
  isNavbarCollapsed = true;
  flagvalue;
  countryName;
  langStoreValue: string;
  defaultFlag: string;
  isOpenSidebar: boolean;
  notif: boolean;
  userRoles = [];
  module = '';
  moduleRh = AppConfig.MODULE_RH;
  currentUser: any;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    public elementRef: ElementRef,
    private rightSidebarService: RightSidebarService,
    private configService: ConfigService,
    private authService: AuthService,
    private router: Router,
    public languageService: LanguageService,
    private localStorage: LocalStorage,
    private spinner: NgxSpinnerService,
    private sseService: SseService,
    private messageService: MessageService,
    private permissionsService: NgxPermissionsService,
    private notificationService: NotificationService
  ) {}
  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    // { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    // { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'العربية', flag: 'assets/images/flags/qatar.png', lang: 'ar' },
  ];
  // notifications: any[] = [
  //   {
  //     userImg: 'assets/images/user/user1.jpg',
  //     userName: 'Sarah Smith',
  //     time: '14 mins ago',
  //     message: 'Please check your mail',
  //   },
  //   {
  //     userImg: 'assets/images/user/user2.jpg',
  //     userName: 'Airi Satou',
  //     time: '22 mins ago',
  //     message: 'Work Completed !!!',
  //   },
  //   {
  //     userImg: 'assets/images/user/user3.jpg',
  //     userName: 'John Doe',
  //     time: '3 hours ago',
  //     message: 'kindly help me for code.',
  //   },
  //   {
  //     userImg: 'assets/images/user/user4.jpg',
  //     userName: 'Ashton Cox',
  //     time: '5 hours ago',
  //     message: 'Lets break for lunch...',
  //   },
  //   {
  //     userImg: 'assets/images/user/user5.jpg',
  //     userName: 'Sarah Smith',
  //     time: '14 mins ago',
  //     message: 'Please check your mail',
  //   },
  //   {
  //     userImg: 'assets/images/user/user6.jpg',
  //     userName: 'Airi Satou',
  //     time: '22 mins ago',
  //     message: 'Work Completed !!!',
  //   },
  //   {
  //     userImg: 'assets/images/user/user7.jpg',
  //     userName: 'John Doe',
  //     time: '3 hours ago',
  //     message: 'kindly help me for code.',
  //   },
  // ];
  notifications = [];
  messages = [];

  ngOnInit() {
    this.notif = false;
    this.config = this.configService.configData;
    this.authService.currentUser.subscribe( data => {
      this.currentUser = data;
      if (data !== null) {
        this.userRoles = this.arrayRemove(data.roles[0], this.localStorage.get('role'));
        this.module = this.localStorage.get('module');
        console.log(this.module);
        if (data.avatar === null) {
          this.userImg = 'assets/images/qbox/avatar-man.png';
        } else {
          this.userImg = data.avatar;
        }
      }
    });

    this.homePage = '/admin/dashboard/main';
    // if (userRole === Role.Admin) {
    //   this.homePage = 'admin/dashboard/main';
    // } else if (userRole === Role.Teacher) {
    //   this.homePage = 'teacher/dashboard';
    // } else if (userRole === Role.Student) {
    //   this.homePage = 'student/dashboard';
    // } else {
    //   this.homePage = 'admin/dashboard/main';
    // }

    this.langStoreValue = localStorage.getItem('lang');
    const val = this.listLang.filter((x) => x.lang === this.langStoreValue);
    this.countryName = val.map((element) => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.defaultFlag = 'assets/images/flags/qatar.png';
      }
    } else {
      this.flagvalue = val.map((element) => element.flag);
    }
    this.getLastNotification();
    this.getLastMessage();
    this.sseService.data.subscribe(data => {
      if (data) {
        this.notif = true;
        this.notifications.splice(0, 0, data);
      }
    });
  }

  ngAfterViewInit() {
    // set theme on startup
    if (localStorage.getItem('theme')) {
      this.renderer.removeClass(this.document.body, this.config.layout.variant);
      this.renderer.addClass(this.document.body, localStorage.getItem('theme'));
    } else {
      this.renderer.addClass(this.document.body, this.config.layout.variant);
    }

    if (localStorage.getItem('menuOption')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('menuOption')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'menu_' + this.config.layout.sidebar.backgroundColor
      );
    }

    if (localStorage.getItem('choose_logoheader')) {
      this.renderer.addClass(
        this.document.body,
        localStorage.getItem('choose_logoheader')
      );
    } else {
      this.renderer.addClass(
        this.document.body,
        'logo-' + this.config.layout.logo_bg_color
      );
    }

    if (localStorage.getItem('sidebar_status')) {
      if (localStorage.getItem('sidebar_status') === 'close') {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      } else {
        this.renderer.removeClass(this.document.body, 'side-closed');
        this.renderer.removeClass(this.document.body, 'submenu-closed');
      }
    } else {
      if (this.config.layout.sidebar.collapsed === true) {
        this.renderer.addClass(this.document.body, 'side-closed');
        this.renderer.addClass(this.document.body, 'submenu-closed');
      }
    }
  }

  callFullscreen() {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.langStoreValue = lang;
    this.languageService.setLanguage(lang);
    if (lang === 'ar') {
      window.location.reload();
      localStorage.setItem('isRtl', 'true');
    } else if (lang === 'en') {
      window.location.reload();
      localStorage.setItem('isRtl', 'false');
    }
  }

  mobileMenuSidebarOpen(event: any, className: string) {
    const hasClass = event.target.classList.contains(className);
    if (hasClass) {
      this.renderer.removeClass(this.document.body, className);
    } else {
      this.renderer.addClass(this.document.body, className);
    }
  }

  callSidemenuCollapse() {
    const hasClass = this.document.body.classList.contains('side-closed');
    if (hasClass) {
      this.renderer.removeClass(this.document.body, 'side-closed');
      this.renderer.removeClass(this.document.body, 'submenu-closed');
    } else {
      this.renderer.addClass(this.document.body, 'side-closed');
      this.renderer.addClass(this.document.body, 'submenu-closed');
    }
  }

  public toggleRightSidebar(): void {
    this.rightSidebarService.sidebarState.subscribe((isRunning) => {
      this.isOpenSidebar = isRunning;
    });

    this.rightSidebarService.setRightSidebar(
      (this.isOpenSidebar = !this.isOpenSidebar)
    );
  }

  logout() {
    this.authService.post('/logout', {}, {}).subscribe(
      res => {
        console.log(res);
      }, error => {
        console.log(error);
      }
    );
    this.authService.currentUserSubject.next(null);
    this.authService.currectResponseSubject.next(null);
    this.localStorage.destroy('currentUser');
    this.localStorage.destroy('response');
    this.router.navigate(['/authentication/signin']);
  }

  getLastNotification() {
    let params = new HttpParams();
    params = params.append('page',   1);
    params = params.append('limit',    5);
    if (this.currentUser.authorities?.call) {
      params = params.append('target',  'CallSon');
    }
    this.notificationService.get('', { params }).subscribe(
      {
        next: response => {
          this.notifications = response.result;
          this.notifications.forEach(item => {
            item.link = this.notificationService.generateNotificationLink(item.target, item.targetId, item.parent?.targetIdParent);
          });
        },
        error: error => {
          console.log(error);
        }
      }
    );
  }

  switchAccount(role) {
    this.localStorage.put('role', role);
    this.authService.userRolesSubject.next(role);
    window.location.reload();
  }

  arrayRemove(arr, value) {
    return arr.filter(ele => {
      return ele !== value;
    });
  }

  getLastMessage() {
    let params = new HttpParams();
    params = params.append('limit',    5);
    this.messageService.get('/getLastMessage', { params }).subscribe(
      {
        next: response => {
          this.messages = response;
        },
        error: error => {
          console.log(error);
        }
      }
    );
  }

  onChangeModule(module) {
    (module === 'EDUCATION') ? module = 'RH' : module = 'EDUCATION';
    this.localStorage.put('module', module);
    this.module = module;
    window.location.reload();
  }
}
