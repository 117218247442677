import {Component, OnInit} from '@angular/core';
import { Event, Router, NavigationStart, NavigationEnd } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PlatformLocation } from '@angular/common';
import {LocalStorage} from './shared/storage/storage.local';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent  implements OnInit {
  currentUrl: string;
  isRtl = true;

  constructor(
    private localStorage: LocalStorage,
    public _router: Router,
    location: PlatformLocation,
    private spinner: NgxSpinnerService
  ) {
    // this._router.events.subscribe((routerEvent: Event) => {
    //   console.log('app spinner event');
    //   if (routerEvent instanceof NavigationStart) {
    //     this.spinner.show();
    //     this.currentUrl = routerEvent.url.substring(
    //       routerEvent.url.lastIndexOf('/') + 1
    //     );
    //   }
    //   if (routerEvent instanceof NavigationEnd) {
    //     this.spinner.hide();
    //   }
    //   window.scrollTo(0, 0);
    // });
  }

  ngOnInit() {
    if (this.localStorage.check('isRtl')) {
      if (this.localStorage.get('isRtl') === 'true') {
        this.isRtl = true;
      } else if (this.localStorage.get('isRtl') === 'false') {
        this.isRtl = false;
      }
    }
  }
}
