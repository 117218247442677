import { AbstractBrowserStorage } from './storage.browser';
import {Injectable} from '@angular/core';

/**
 * A storage object using local storage for persistence
 *
 * @export
 * @class LocalStorage
 * @extends {AbstractBrowserStorage}
 *
 */
@Injectable({
  providedIn: 'root',
})
export class LocalStorage extends AbstractBrowserStorage {
  constructor() {
    super(localStorage);
  }
}
