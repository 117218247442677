import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {AppConfig} from '../../app.config';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResourceService {

  private apiUrl = AppConfig.API_URL;
  private argUrl: string;

  constructor(protected httpClient: HttpClient, argUrl) {
    this.argUrl = argUrl;
  }

  get<T>(path: string, options): Observable<any> {
    return this.httpClient.get<T>(`${this.apiUrl}${this.argUrl}${path}`, options);
  }

  post<T>(path: string, body: any = {}, options): Observable<any> {
    return this.httpClient.post<T>(`${this.apiUrl}${this.argUrl}${path}`, body, options);
  }

  put<T>(path: string, body: any = {}, options): Observable<any> {
    return this.httpClient.put<T>(`${this.apiUrl}${this.argUrl}${path}`, body, options);
  }

  delete<T>(path, options): Observable<any> {
    return this.httpClient.delete<T>(`${this.apiUrl}${this.argUrl}${path}`, options);
  }

  patch<T>(path: string, body: any = {}, options): Observable<any> {
    return this.httpClient.patch<T>(`${this.apiUrl}${this.argUrl}${path}`, body, options);
  }
}
