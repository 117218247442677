import { RouteInfo } from './sidebar.metadata';
export const ROUTES: RouteInfo[] = [
    {
    path: '/admin/dashboard/main',
    title: 'MENUITEMS.HOME.TEXT',
    moduleName: 'dashboard',
    iconType: 'fas',
    icon: 'fa-home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.TEXT',
    moduleName: '',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN', 'USER_VIEW'],
    submenu: [],
  },
  {
    path: '',
    title: 'MENUITEMS.AGENTS.TEXT',
    moduleName: 'agents',
    iconType: 'fas',
    icon: 'fa-user-cog',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN', 'USER_VIEW'],
    submenu: [
      {
        path: '/web/user/list/agent',
        title: 'MENUITEMS.AGENTSALL.TEXT',
        moduleName: 'agents',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
      },
      {
        path: '/web/user/add/agent',
        title: 'MENUITEMS.AGENTSADD.TEXT',
        moduleName: 'agents',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'USER_POST'],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.TEACHERS.TEXT',
    moduleName: 'teachers',
    iconType: 'fas',
    icon: 'fa-user-tie',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN', 'USER_VIEW'],
    submenu: [
      {
        path: '/web/user/list/teacher',
        title: 'MENUITEMS.TEACHERSALL.TEXT',
        moduleName: 'teachers',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
      },
      {
        path: '/web/user/add/teacher',
        title: 'MENUITEMS.TEACHERSADD.TEXT',
        moduleName: 'teachers',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'USER_POST'],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.STUDENTS.TEXT',
    moduleName: 'students',
    iconType: 'fas',
    icon: 'fa-user-graduate',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN', 'USER_VIEW'],
    submenu: [
      {
        path: '/web/user/list/student',
        title: 'MENUITEMS.STUDENTSALL.TEXT',
        moduleName: 'students',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
      },
      {
        path: '/web/user/add/student',
        title: 'MENUITEMS.STUDENTSADD.TEXT',
        moduleName: 'students',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'USER_POST'],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.PARENTS.TEXT',
    moduleName: 'parents',
    iconType: 'fas',
    icon: 'fa-user-friends',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN', 'USER_VIEW'],
    submenu: [
      {
        path: '/web/user/list/parent',
        title: 'MENUITEMS.PARENTSALL.TEXT',
        moduleName: 'parents',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
      },
      {
        path: '/web/user/add/parent',
        title: 'MENUITEMS.PARENTSADD.TEXT',
        moduleName: 'parents',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'USER_POST'],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.MAINOTHER.TEXT',
    moduleName: '',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN', 'ROLE_AGENT'],
    submenu: [],
  },
  {
    path: '/web/classroom',
    title: 'MENUITEMS.CLASSROOMS.TEXT',
    moduleName: 'classrooms',
    iconType: 'fas',
    icon: 'fa-landmark',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN', 'CLASSROOM_VIEW'],
    submenu: [],
  },
  {
    path: '',
    title: 'MENUITEMS.COURSES_ALL.TEXT',
    moduleName: 'courses',
    iconType: 'fas',
    icon: 'fa-book',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN', 'SUBJECT_VIEW', 'STUDY_VIEW', 'LESSON_PLANREVIEW', 'REPORT_VIEW'],
    submenu: [
      {
        path: '/web/subject/list',
        title: 'MENUITEMS.COURSES.TEXT',
        moduleName: 'courses',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'SUBJECT_VIEW'],
        submenu: [],
      },
      {
        path: '/web/subject/study',
        title: 'MENUITEMS.COURSESSUBJECT.TEXT',
        moduleName: 'courses',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'STUDY_VIEW'],
        submenu: [],
      },
      // {
      //   path: '/web/subject/study/list/public',
      //   title: 'MENUITEMS.PUBLICCOURSESSUBJECT.TEXT',
      //   moduleName: 'courses',
      //   iconType: '',
      //   icon: '',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   role: ['ROLE_ADMIN', 'STUDY_VIEW'],
      //   submenu: [],
      // },
      {
        path: '/web/plan',
        title: 'MENUITEMS.PLAN.TEXT',
        moduleName: 'courses',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'LESSON_PLANREVIEW'],
        submenu: [],
      },
      {
        path: '/web/report',
        title: 'MENUITEMS.REPORTMODEL.TEXT',
        moduleName: 'courses',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'REPORT_VIEW'],
        submenu: [],
      },
      {
        path: '/web/path',
        title: 'MENUITEMS.PATHS.TEXT',
        moduleName: 'courses',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'REPORT_AGENT'],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.COURSES_ALL.TEXT',
    moduleName: 'courses',
    iconType: 'fas',
    icon: 'fa-book',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_TEACHER'],
    submenu: [
      {
        path: '/web/subject/list',
        title: 'MENUITEMS.COURSES.TEXT',
        moduleName: 'courses',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
      },
      {
        path: '/web/plan',
        title: 'MENUITEMS.PLAN.TEXT',
        moduleName: 'courses',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'LESSON_PLANREVIEW', 'ROLE_TEACHER'],
        submenu: [],
      },
    ],
  },
  {
    path: '/web/study-public',
    title: 'MENUITEMS.PUBLICPUBLICATION.TEXT',
    moduleName: 'study-public',
    iconType: 'fas',
    icon: 'fa-stream',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN', 'PUBLIC_PUBLICATION_PUBLISH', 'ROLE_TEACHER', 'ROLE_STUDENT', 'ROLE_PARENT'],
    submenu: [],
  },
  {
    path: '/web/ressource',
    title: 'MENUITEMS.SUPPORTS.TEXT',
    moduleName: 'educational-ressources',
    iconType: 'fas',
    icon: 'fa-newspaper',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_TEACHER'],
    submenu: [],
  },
  {
    path: '/web/bank/home',
    title: 'MENUITEMS.BANKQUESTIONS.TEXT',
    moduleName: 'bank-ressources',
    iconType: 'fas',
    icon: 'fa-cubes',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN', 'BANK_VIEW', 'ROLE_TEACHER'],
    submenu: [],
  },
  // {
  //   path: '',
  //   title: 'MENUITEMS.EDUCATIONALRESSOURCES.TEXT',
  //   moduleName: 'educationalressources',
  //   iconType: 'fas',
  //   icon: 'fa-newspaper',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_TEACHER'],
  //   submenu: [
  //     {
  //       path: '/web/ressource',
  //       title: 'MENUITEMS.SUPPORTS.TEXT',
  //       moduleName: 'educationalressources',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: ['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_TEACHER'],
  //       submenu: [],
  //     },
  //     {
  //       path: '/web/bank/home',
  //       title: 'MENUITEMS.BANKQUESTIONS.TEXT',
  //       moduleName: 'educationalressources',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: ['ROLE_ADMIN', 'BANK_VIEW', 'ROLE_TEACHER'],
  //       submenu: [],
  //     },
  //   ],
  // },
  {
    path: '/web/subject/list',
    title: 'MENUITEMS.COURSES_ALL.TEXT',
    moduleName: 'courses',
    iconType: 'fas',
    icon: 'fa-book',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_STUDENT', 'ROLE_PARENT'],
    submenu: [],
  },
  {
    path: '/web/session/schedule',
    title: 'MENUITEMS.TIMETABLE.TEXT',
    moduleName: 'timetable',
    iconType: 'fas',
    icon: 'fa-calendar-alt',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_TEACHER', 'ROLE_STUDENT', 'ROLE_PARENT'],
    submenu: [],
  },
  {
    path: '',
    title: 'MENUITEMS.TIMETABLE.TEXT',
    moduleName: 'timetable',
    iconType: 'fas',
    icon: 'fa-calendar-alt',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN', 'TIMETABLE_VIEW'],
    submenu: [
      {
        path: '/web/session/schedule',
        title: 'MENUITEMS.TIMETABLES.TEXT',
        moduleName: 'timetable',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'ROLE_AGENT'],
        submenu: [],
      },
      {
        path: '/web/timetable',
        title: 'MENUITEMS.TIMETABLE_GENERATE.TEXT',
        moduleName: 'timetable',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'TIMETABLE_VIEW'],
        submenu: [],
      },
      {
        path: '/web/timetable/teacher',
        title: 'MENUITEMS.TIMETABLE_TEACHER.TEXT',
        moduleName: 'timetable',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'ROLE_AGENT'],
        submenu: [],
      },
    ],
  },
  {
    path: '/web/reporting/student',
    title: 'MENUITEMS.STUDYREPORTS.TEXT',
    moduleName: 'reporting',
    iconType: 'fas',
    icon: 'fa-user-graduate',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_PARENT', 'ROLE_STUDENT'],
    submenu: [],
  },
  {
    path: '/web/calendar',
    title: 'MENUITEMS.SHEDULE.TEXT',
    moduleName: 'shedule',
    iconType: 'fas',
    icon: 'fa-calendar-times',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },
  // {
  //   path: '/web/ressource',
  //   title: 'MENUITEMS.SUPPORTS.TEXT',
  //   moduleName: '',
  //   iconType: 'fas',
  //   icon: 'fa-newspaper',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_TEACHER'],
  //   submenu: [],
  // },
  {
    path: '/web/statistique',
    title: 'MENUITEMS.STATISTIC.TEXT',
    moduleName: 'statistic',
    iconType: 'fas',
    icon: 'fa-chart-line',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN', 'STATISTIQUE_VIEW'],
    submenu: [],
  },
  // {
  //   path: '/web/behavior',
  //   title: 'MENUITEMS.BEHAVIOR.TEXT',
  //   moduleName: '',
  //   iconType: 'far',
  //   icon: 'fa-angry',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['ROLE_ADMIN', 'ROLE_AGENT', 'ROLE_TEACHER'],
  //   submenu: [],
  // },
  {
    path: '',
    title: 'MENUITEMS.STUDENTSAFFAIRS.TEXT',
    moduleName: 'studentsAffairs',
    iconType: 'fas',
    icon: 'fa-user-friends',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN', 'ABSENCE_POST', 'DEGREES_VIEW', 'CALL_GET'],
    submenu: [
      // {
      //   path: '/web/behavior',
      //   title: 'MENUITEMS.STUDENTSBEHAVIOR.TEXT',
      //   moduleName: 'studentsAffairs',
      //   iconType: '',
      //   icon: '',
      //   class: 'ml-menu',
      //   groupTitle: false,
      //   badge: '',
      //   badgeClass: '',
      //   role: ['BEHAVIOR_VIEW'],
      //   submenu: [],
      // },
      {
        path: '/web/session/absence/daily',
        title: 'MENUITEMS.STUDENTSABSENCES.TEXT',
        moduleName: 'studentsAffairs',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'ABSENCE_POST'],
        submenu: [],
      },
      {
        path: '/web/reporting/student',
        title: 'MENUITEMS.STUDENTREPORTS.TEXT',
        moduleName: 'studentsAffairs',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'DEGREES_VIEW'],
        submenu: [],
      },
      {
        path: '/web/reporting/study',
        title: 'MENUITEMS.STUDENT_DEGREE.TEXT',
        moduleName: 'studentsAffairs',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'DEGREES_VIEW'],
        submenu: [],
      },
      {
        path: '/web/notification/call',
        title: 'NOTIFICATION.CALL.TEXT',
        moduleName: 'studentsAffairs',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'CALL_GET'],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.STUDENTSBEHAVIOR.TEXT',
    moduleName: 'behaviors',
    iconType: 'fas',
    icon: 'fa-user-friends',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN',  'BEHAVIOR_REPORT', 'BEHAVIOR_REINFORCE', 'BEHAVIOR_CONTROL', 'ROLE_TEACHER'],
    submenu: [
      {
        path: '/web/behavior',
        title: 'MENUITEMS.MONITORBEHAVIOR.TEXT',
        moduleName: 'behaviors',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'ROLE_TEACHER', 'BEHAVIOR_CONTROL'],
        submenu: [],
      },
      {
        path: '/web/behavior/report/global/certificate',
        title: 'MENUITEMS.DISPLAYREPORTBEHAVIOR.TEXT',
        moduleName: 'report_behaviors',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'ROLE_TEACHER', 'BEHAVIOR_REPORT'],
        submenu: [],
      },
      {
        path: '/web/behavior/report',
        title: 'MENUITEMS.REPORTBEHAVIOR.TEXT',
        moduleName: 'report_behaviors',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'ROLE_TEACHER', 'BEHAVIOR_REPORT'],
        submenu: [],
      },
      {
        path: '/web/behavior/certificate',
        title: 'MENUITEMS.CERTIFICATESBEHAVIOR.TEXT',
        moduleName: 'certification_behaviors',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_STUDENT', 'ROLE_PARENT'],
        submenu: [],
      },
      {
        path: '',
        title: 'MENUITEMS.REINFORCEBEHAVIOR.TEXT',
        moduleName: 'reinforce_behaviors',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'ROLE_TEACHER', 'BEHAVIOR_REINFORCE'],
        submenu: [
          {
            path: '/web/behavior/note',
            title: 'MENUITEMS.REINFORCEBEHAVIORPOINTS.TEXT',
            moduleName: 'reinforce_behaviors',
            iconType: '',
            icon: '',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['ROLE_ADMIN', 'ROLE_TEACHER', 'BEHAVIOR_REINFORCE'],
            submenu: [],
          },
          {
            path: '/web/behavior/certificate',
            title: 'MENUITEMS.CERTIFICATESBEHAVIOR.TEXT',
            moduleName: 'reinforce_behaviors',
            iconType: '',
            icon: '',
            class: 'ml-menu',
            groupTitle: false,
            badge: '',
            badgeClass: '',
            role: ['ROLE_ADMIN', 'ROLE_TEACHER', 'BEHAVIOR_REINFORCE'],
            submenu: [],
          }
        ],
      },
    ],
  },

  {
    path: '',
    title: 'MENUITEMS.STUDENTSBEHAVIOR.TEXT',
    moduleName: 'behaviors',
    iconType: 'fas',
    icon: 'fa-user-friends',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_STUDENT', 'ROLE_PARENT'],
    submenu: [
      {
        path: '/web/behavior',
        title: 'MENUITEMS.DISPLAYBEHAVIOR.TEXT',
        moduleName: 'behaviors',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_STUDENT', 'ROLE_PARENT'],
        submenu: [],
      },
      {
        path: '/web/behavior/report',
        title: 'MENUITEMS.DISPLAYREPORTBEHAVIOR.TEXT',
        moduleName: 'behaviors',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_STUDENT', 'ROLE_PARENT'],
        submenu: [],
      },
      {
        path: '/web/behavior/certificate',
        title: 'MENUITEMS.CERTIFICATESBEHAVIOR.TEXT',
        moduleName: 'behaviors',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_STUDENT', 'ROLE_PARENT'],
        submenu: [],
      },
    ],
  },
  // {
  //   path: '/web/behavior/parent',
  //   title: 'MENUITEMS.PARENTSBEHAVIOR.TEXT',
  //   moduleName: '',
  //   iconType: 'far',
  //   icon: 'fa-angry',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['ROLE_PARENT'],
  //   submenu: [],
  // },
  // {
  //   path: '/web/behavior',
  //   title: 'MENUITEMS.STUDENTSBEHAVIOR.TEXT',
  //   moduleName: '',
  //   iconType: 'far',
  //   icon: 'fa-angry',
  //   class: '',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['ROLE_TEACHER'],
  //   submenu: [],
  // },
  {
    path: '#',
    title: 'MENUITEMS.ARCHIVE.TEXT',
    moduleName: 'archive',
    iconType: 'fas',
    icon: 'fa-file-archive',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN', 'ARCHIVE_VIEW'],
    submenu: [],
  },
  {
    path: '/web/ads',
    title: 'MENUITEMS.ADS.TEXT',
    moduleName: 'ads',
    iconType: 'fas',
    icon: 'fa-audio-description',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN', 'ADS_PUBLISH'],
    submenu: [],
  },
  {
    path: '/web/faq',
    title: 'MENUITEMS.FAQ.TEXT',
    moduleName: 'faq',
    iconType: 'fas',
    icon: 'fa-question',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN'],
    submenu: [],
  },
  {
    path: '',
    title: 'BUS.BUSES_TRACKING.TEXT',
    moduleName: 'busTracking',
    iconType: 'fas',
    icon: 'fa-bus-alt',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN', 'ROLE_PARENT', 'ROLE_BUSCORDINATOR', 'ROLE_DRIVER'],
    submenu: [
      {
        path: '/web/bus-tracking/bus',
        title: 'BUS.BUSES.TEXT',
        moduleName: 'busTracking',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'ROLE_BUSCORDINATOR', 'ROLE_DRIVER', 'ROLE_PARENT'],
        submenu: [],
      },
      {
        path: '/web/bus-tracking/trip',
        title: 'TRIP.TRIPS.TEXT',
        moduleName: 'busTracking',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'ROLE_BUSCORDINATOR', 'ROLE_DRIVER'],
        submenu: [],
      },
      {
        path: '/web/bus-tracking/educational',
        title: 'TRIP.EDUCATIONAL_TRIPS.TEXT',
        moduleName: 'busTracking',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'ROLE_BUSCORDINATOR', 'ROLE_DRIVER'],
        submenu: [],
      },
      {
        path: '/web/bus-tracking/group',
        title: 'GROUP_TRIP.GROUPS.TEXT',
        moduleName: 'busTracking',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'ROLE_BUSCORDINATOR', 'ROLE_DRIVER'],
        submenu: [],
      },
      {
        path: '/web/bus-tracking/availability/student',
        title: 'TRIP.AVAILABILITY_STUDENTS.TEXT',
        moduleName: 'busTracking',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'ROLE_PARENT', 'ROLE_BUSCORDINATOR'],
        submenu: [],
      },
      {
        path: '/web/bus-tracking/availability/location',
        title: 'BUS.STUDENT_RESIDENCE.TEXT',
        moduleName: 'busTracking',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN', 'ROLE_PARENT', 'ROLE_BUSCORDINATOR', 'ROLE_DRIVER'],
        submenu: [],
      },
      {
        path: '/web/user/list/buscordinator',
        title: 'BUS.BUSES_COORDINATORS.TEXT',
        moduleName: 'busTracking',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN'],
        submenu: [],
      },
      {
        path: '/web/user/list/driver',
        title: 'BUS.DRIVERS.TEXT',
        moduleName: 'busTracking',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN'],
        submenu: [],
      },
      {
        path: '/web/bus-tracking/archive',
        title: 'TRIP.ARCHIVE.TEXT',
        moduleName: 'busTracking',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN'],
        submenu: [],
      },
      {
        path: '/web/bus-tracking/setting',
        title: 'BUS.SETTING.TEXT',
        moduleName: 'busTracking',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN'],
        submenu: [],
      },
    ],
  },
  {
    path: '/web/setting',
    title: 'MENUITEMS.SETTINGS.TEXT',
    moduleName: 'settings',
    iconType: 'fas',
    icon: 'fa-cog',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN'],
    submenu: [],
  },
  // {
  //   path: '',
  //   title: 'MENUITEMS.MAIN.TEXT',
  //   moduleName: '',
  //   iconType: '',
  //   icon: '',
  //   class: '',
  //   groupTitle: true,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['All'],
  //   submenu: [],
  // },
//
//   // Admin Modules
//
//   {
//     path: '',
//     title: 'MENUITEMS.HOME.TEXT',
//     moduleName: 'dashboard',
//     iconType: 'material-icons-two-tone',
//     icon: 'home',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Admin'],
//     submenu: [
//       {
//         path: '/admin/dashboard/main',
//         title: 'MENUITEMS.HOME.LIST.DASHBOARD1',
//         moduleName: 'dashboard',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/dashboard/dashboard2',
//         title: 'MENUITEMS.HOME.LIST.DASHBOARD2',
//         moduleName: 'dashboard',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/dashboard/teacher-dashboard',
//         title: 'MENUITEMS.HOME.LIST.TEACHER-DASHBOARD',
//         moduleName: 'teacher-dashboard',
//         iconType: 'material-icons-two-tone',
//         icon: '',
//         class: '',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/dashboard/student-dashboard',
//         title: 'MENUITEMS.HOME.LIST.STUDENT-DASHBOARD',
//         moduleName: 'student-dashboard',
//         iconType: 'material-icons-two-tone',
//         icon: '',
//         class: '',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//   {
//     path: '',
//     title: 'MENUITEMS.TEACHERS.TEXT',
//     moduleName: 'teachers',
//     iconType: 'material-icons-two-tone',
//     icon: 'person',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Admin'],
//     submenu: [
//       {
//         path: '/admin/teachers/all-teachers',
//         title: 'MENUITEMS.TEACHERS.LIST.ALL-TEACHERS',
//         moduleName: 'teachers',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/teachers/add-teacher',
//         title: 'MENUITEMS.TEACHERS.LIST.ADD-TEACHER',
//         moduleName: 'teachers',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/teachers/edit-teacher',
//         title: 'MENUITEMS.TEACHERS.LIST.EDIT-TEACHER',
//         moduleName: 'teachers',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/teachers/about-teacher',
//         title: 'MENUITEMS.TEACHERS.LIST.ABOUT-TEACHER',
//         moduleName: 'teachers',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//   {
//     path: '',
//     title: 'MENUITEMS.STUDENTS.TEXT',
//     moduleName: 'students',
//     iconType: 'material-icons-two-tone',
//     icon: 'people_alt',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Admin'],
//     submenu: [
//       {
//         path: '/admin/students/all-students',
//         title: 'MENUITEMS.STUDENTS.LIST.ALL-STUDENTS',
//         moduleName: 'students',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/students/add-student',
//         title: 'MENUITEMS.STUDENTS.LIST.ADD-STUDENT',
//         moduleName: 'students',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/students/edit-student',
//         title: 'MENUITEMS.STUDENTS.LIST.EDIT-STUDENT',
//         moduleName: 'students',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/students/about-student',
//         title: 'MENUITEMS.STUDENTS.LIST.ABOUT-STUDENT',
//         moduleName: 'students',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//   {
//     path: '',
//     title: 'MENUITEMS.COURSES.TEXT',
//     moduleName: 'courses',
//     iconType: 'material-icons-two-tone',
//     icon: 'school',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Admin'],
//     submenu: [
//       {
//         path: '/admin/courses/all-courses',
//         title: 'MENUITEMS.COURSES.LIST.ALL-COURSES',
//         moduleName: 'courses',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/courses/add-course',
//         title: 'MENUITEMS.COURSES.LIST.ADD-COURSE',
//         moduleName: 'courses',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/courses/edit-course',
//         title: 'MENUITEMS.COURSES.LIST.EDIT-COURSE',
//         moduleName: 'courses',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/courses/about-course',
//         title: 'MENUITEMS.COURSES.LIST.ABOUT-COURSE',
//         moduleName: 'courses',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//   {
//     path: '',
//     title: 'MENUITEMS.LIBRARY.TEXT',
//     moduleName: 'library',
//     iconType: 'material-icons-two-tone',
//     icon: 'local_library',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Admin'],
//     submenu: [
//       {
//         path: '/admin/library/all-assets',
//         title: 'MENUITEMS.LIBRARY.LIST.ALL-LIBRARY',
//         moduleName: 'library',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/library/add-asset',
//         title: 'MENUITEMS.LIBRARY.LIST.ADD-LIBRARY',
//         moduleName: 'library',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/library/edit-asset',
//         title: 'MENUITEMS.LIBRARY.LIST.EDIT-LIBRARY',
//         moduleName: 'library',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//   {
//     path: '',
//     title: 'MENUITEMS.DEPARTMENTS.TEXT',
//     moduleName: 'departments',
//     iconType: 'material-icons-two-tone',
//     icon: 'business',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Admin'],
//     submenu: [
//       {
//         path: '/admin/departments/all-departments',
//         title: 'MENUITEMS.DEPARTMENTS.LIST.ALL-DEPARTMENT',
//         moduleName: 'departments',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/departments/add-department',
//         title: 'MENUITEMS.DEPARTMENTS.LIST.ADD-DEPARTMENT',
//         moduleName: 'departments',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/departments/edit-department',
//         title: 'MENUITEMS.DEPARTMENTS.LIST.EDIT-DEPARTMENT',
//         moduleName: 'departments',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//   {
//     path: '',
//     title: 'MENUITEMS.STAFF.TEXT',
//     moduleName: 'staff',
//     iconType: 'material-icons-two-tone',
//     icon: 'face',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Admin'],
//     submenu: [
//       {
//         path: '/admin/staff/all-staff',
//         title: 'MENUITEMS.STAFF.LIST.ALL-STAFF',
//         moduleName: 'staff',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/staff/add-staff',
//         title: 'MENUITEMS.STAFF.LIST.ADD-STAFF',
//         moduleName: 'staff',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/staff/edit-staff',
//         title: 'MENUITEMS.STAFF.LIST.EDIT-STAFF',
//         moduleName: 'staff',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/staff/about-staff',
//         title: 'MENUITEMS.STAFF.LIST.ABOUT-STAFF',
//         moduleName: 'staff',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//   {
//     path: '',
//     title: 'MENUITEMS.HOLIDAY.TEXT',
//     moduleName: 'holidays',
//     iconType: 'material-icons-two-tone',
//     icon: 'airline_seat_individual_suite',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Admin'],
//     submenu: [
//       {
//         path: '/admin/holidays/all-holidays',
//         title: 'MENUITEMS.HOLIDAY.LIST.ALL-HOLIDAY',
//         moduleName: 'holidays',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/holidays/add-holiday',
//         title: 'MENUITEMS.HOLIDAY.LIST.ADD-HOLIDAY',
//         moduleName: 'holidays',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/holidays/edit-holiday',
//         title: 'MENUITEMS.HOLIDAY.LIST.EDIT-HOLIDAY',
//         moduleName: 'holidays',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//   {
//     path: '',
//     title: 'MENUITEMS.FEES.TEXT',
//     moduleName: 'fees',
//     iconType: 'material-icons-two-tone',
//     icon: 'monetization_on',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Admin'],
//     submenu: [
//       {
//         path: '/admin/fees/all-fees',
//         title: 'MENUITEMS.FEES.LIST.ALL-FEES',
//         moduleName: 'fees',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/fees/add-fees',
//         title: 'MENUITEMS.FEES.LIST.ADD-FEES',
//         moduleName: 'fees',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/fees/edit-fees',
//         title: 'MENUITEMS.FEES.LIST.EDIT-FEES',
//         moduleName: 'fees',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/admin/fees/fee-receipt',
//         title: 'MENUITEMS.FEES.LIST.FEE-RECEIPT',
//         moduleName: 'fees',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//
//   // Teacher Modules
//
//   {
//     path: '/teacher/dashboard',
//     title: 'MENUITEMS.TEACHER.LIST.DASHBOARD',
//     moduleName: 'dashboard',
//     iconType: 'material-icons-two-tone',
//     icon: 'home',
//     class: '',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Teacher'],
//     submenu: [],
//   },
//   {
//     path: '/teacher/lectures',
//     title: 'MENUITEMS.TEACHER.LIST.LECTURES',
//     moduleName: 'lectures',
//     iconType: 'material-icons-two-tone',
//     icon: 'menu_book',
//     class: '',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Teacher'],
//     submenu: [],
//   },
//   {
//     path: '/teacher/leave-request',
//     title: 'MENUITEMS.TEACHER.LIST.LEAVE-REQUEST',
//     moduleName: 'leave-request',
//     iconType: 'material-icons-two-tone',
//     icon: 'offline_pin',
//     class: '',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Teacher'],
//     submenu: [],
//   },
//   {
//     path: '/teacher/exam-schedule',
//     title: 'MENUITEMS.TEACHER.LIST.EXAM-SCHEDULE',
//     moduleName: 'exam-schedule',
//     iconType: 'material-icons-two-tone',
//     icon: 'history_edu',
//     class: '',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Teacher'],
//     submenu: [],
//   },
//   {
//     path: '/teacher/settings',
//     title: 'MENUITEMS.TEACHER.LIST.SETTINGS',
//     moduleName: 'settings',
//     iconType: 'material-icons-two-tone',
//     icon: 'settings',
//     class: '',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Teacher'],
//     submenu: [],
//   },
//
//   // Student Modules
//
//   {
//     path: '/student/dashboard',
//     title: 'MENUITEMS.STUDENT.LIST.DASHBOARD',
//     moduleName: 'dashboard',
//     iconType: 'material-icons-two-tone',
//     icon: 'home',
//     class: '',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Student'],
//     submenu: [],
//   },
//   {
//     path: '/student/homework',
//     title: 'MENUITEMS.STUDENT.LIST.HOMEWORK',
//     moduleName: 'homework',
//     iconType: 'material-icons-two-tone',
//     icon: 'article',
//     class: '',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Student'],
//     submenu: [],
//   },
//   {
//     path: '/student/leave-request',
//     title: 'MENUITEMS.STUDENT.LIST.LEAVE-REQUEST',
//     moduleName: 'leave-request',
//     iconType: 'material-icons-two-tone',
//     icon: 'offline_pin',
//     class: '',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Student'],
//     submenu: [],
//   },
//   {
//     path: '/student/timetable',
//     title: 'MENUITEMS.STUDENT.LIST.TIME-TABLE',
//     moduleName: 'timetable',
//     iconType: 'material-icons-two-tone',
//     icon: 'table_chart',
//     class: '',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Student'],
//     submenu: [],
//   },
//   {
//     path: '/student/settings',
//     title: 'MENUITEMS.STUDENT.LIST.SETTINGS',
//     moduleName: 'settings',
//     iconType: 'material-icons-two-tone',
//     icon: 'settings',
//     class: '',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Student'],
//     submenu: [],
//   },
//   // Common Module
//
//   {
//     path: '',
//     title: 'MENUITEMS.APPS.TEXT',
//     moduleName: '',
//     iconType: '',
//     icon: '',
//     class: '',
//     groupTitle: true,
//     badge: '',
//     badgeClass: '',
//     role: ['All'],
//     submenu: [],
//   },
//   {
//     path: 'calendar',
//     title: 'MENUITEMS.CALENDAR.TEXT',
//     moduleName: 'calendar',
//     iconType: 'material-icons-two-tone',
//     icon: 'event_note',
//     class: '',
//     groupTitle: false,
//     badge: 'New',
//     badgeClass: 'badge bg-blue sidebar-badge float-end',
//     role: ['All'],
//     submenu: [],
//   },
//   {
//     path: 'task',
//     title: 'MENUITEMS.TASK.TEXT',
//     moduleName: 'task',
//     iconType: 'material-icons-two-tone',
//     icon: 'fact_check',
//     class: '',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['All'],
//     submenu: [],
//   },
//   {
//     path: 'contacts',
//     title: 'MENUITEMS.CONTACTS.TEXT',
//     moduleName: 'contacts',
//     iconType: 'material-icons-two-tone',
//     icon: 'contacts',
//     class: '',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['All'],
//     submenu: [],
//   },
//   {
//     path: '',
//     title: 'Email',
//     moduleName: 'email',
//     iconType: 'material-icons-two-tone',
//     icon: 'email',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['All'],
//     submenu: [
//       {
//         path: '/email/inbox',
//         title: 'Inbox',
//         moduleName: 'email',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/email/compose',
//         title: 'Compose',
//         moduleName: 'email',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/email/read-mail',
//         title: 'Read Email',
//         moduleName: 'email',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//   {
//     path: '',
//     title: 'More Apps',
//     moduleName: 'apps',
//     iconType: 'material-icons-two-tone',
//     icon: 'stars',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '5',
//     badgeClass: 'badge bg-orange sidebar-badge float-end',
//     role: ['All'],
//     submenu: [
//       {
//         path: '/apps/chat',
//         title: 'Chat',
//         moduleName: 'apps',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/apps/dragdrop',
//         title: 'Drag & Drop',
//         moduleName: 'apps',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/apps/contact-grid',
//         title: 'Contact Grid',
//         moduleName: 'apps',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/apps/support',
//         title: 'Support',
//         moduleName: 'apps',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//   {
//     path: '',
//     title: 'Widgets',
//     moduleName: 'widget',
//     iconType: 'material-icons-two-tone',
//     icon: 'widgets',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Admin'],
//     submenu: [
//       {
//         path: '/widget/chart-widget',
//         title: 'Chart Widget',
//         moduleName: 'widget',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/widget/data-widget',
//         title: 'Data Widget',
//         moduleName: 'widget',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//   {
//     path: '',
//     title: '-- Components',
//     moduleName: '',
//     iconType: '',
//     icon: '',
//     class: '',
//     groupTitle: true,
//     badge: '',
//     badgeClass: '',
//     role: ['Admin'],
//     submenu: [],
//   },
//   {
//     path: '',
//     title: 'User Interface (UI)',
//     moduleName: 'ui',
//     iconType: 'material-icons-two-tone',
//     icon: 'dvr',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Admin'],
//     submenu: [
//       {
//         path: '/ui/alerts',
//         title: 'Alerts',
//         moduleName: 'ui',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/ui/badges',
//         title: 'Badges',
//         moduleName: 'ui',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/ui/chips',
//         title: 'Chips',
//         moduleName: 'ui',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/ui/modal',
//         title: 'Modal',
//         moduleName: 'ui',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/ui/buttons',
//         title: 'Buttons',
//         moduleName: 'ui',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/ui/expansion-panel',
//         title: 'Expansion Panel',
//         moduleName: 'ui',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/ui/bottom-sheet',
//         title: 'Bottom Sheet',
//         moduleName: 'ui',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/ui/dialogs',
//         title: 'Dialogs',
//         moduleName: 'ui',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/ui/cards',
//         title: 'Cards',
//         moduleName: 'ui',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/ui/labels',
//         title: 'Labels',
//         moduleName: 'ui',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/ui/list-group',
//         title: 'List Group',
//         moduleName: 'ui',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/ui/snackbar',
//         title: 'Snackbar',
//         moduleName: 'ui',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/ui/preloaders',
//         title: 'Preloaders',
//         moduleName: 'ui',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/ui/progressbars',
//         title: 'Progress Bars',
//         moduleName: 'ui',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/ui/tabs',
//         title: 'Tabs',
//         moduleName: 'ui',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/ui/typography',
//         title: 'Typography',
//         moduleName: 'ui',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/ui/helper-classes',
//         title: 'Helper Classes',
//         moduleName: 'ui',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//   {
//     path: '',
//     title: 'Forms',
//     moduleName: 'forms',
//     iconType: 'material-icons-two-tone',
//     icon: 'subtitles',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Admin'],
//     submenu: [
//       {
//         path: '/forms/form-controls',
//         title: 'Form Controls',
//         moduleName: 'forms',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/forms/advance-controls',
//         title: 'Advanced Controls',
//         moduleName: 'forms',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/forms/form-example',
//         title: 'Form Examples',
//         moduleName: 'forms',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/forms/form-validation',
//         title: 'Form Validation',
//         moduleName: 'forms',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/forms/wizard',
//         title: 'Form Wizard',
//         moduleName: 'forms',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/forms/editors',
//         title: 'Editors',
//         moduleName: 'forms',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//   {
//     path: '',
//     title: 'Tables',
//     moduleName: 'tables',
//     iconType: 'material-icons-two-tone',
//     icon: 'view_list',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Admin'],
//     submenu: [
//       {
//         path: '/tables/basic-tables',
//         title: 'Basic Tables',
//         moduleName: 'tables',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/tables/material-tables',
//         title: 'Material Tables',
//         moduleName: 'tables',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/tables/ngx-datatable',
//         title: 'ngx-datatable',
//         moduleName: 'tables',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//   {
//     path: '',
//     title: 'Medias',
//     moduleName: 'media',
//     iconType: 'material-icons-two-tone',
//     icon: 'image_search',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Admin'],
//     submenu: [
//       {
//         path: '/media/gallery',
//         title: 'Image Gallery',
//         moduleName: 'media',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//   {
//     path: '',
//     title: 'Charts',
//     moduleName: 'charts',
//     iconType: 'material-icons-two-tone',
//     icon: 'insert_chart',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '7',
//     badgeClass: 'badge bg-green sidebar-badge float-end',
//     role: ['Admin'],
//     submenu: [
//       {
//         path: '/charts/echart',
//         title: 'Echart',
//         moduleName: 'charts',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/charts/apex',
//         title: 'Apex',
//         moduleName: 'charts',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/charts/chartjs',
//         title: 'ChartJS',
//         moduleName: 'charts',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/charts/ngx-charts',
//         title: 'Ngx-Charts',
//         moduleName: 'charts',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/charts/gauge',
//         title: 'Gauge',
//         moduleName: 'charts',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//   {
//     path: '',
//     title: 'Timeline',
//     moduleName: 'timeline',
//     iconType: 'material-icons-two-tone',
//     icon: 'timeline',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Admin'],
//     submenu: [
//       {
//         path: '/timeline/timeline1',
//         title: 'Timeline 1',
//         moduleName: 'timeline',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/timeline/timeline2',
//         title: 'Timeline 2',
//         moduleName: 'timeline',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//   {
//     path: '',
//     title: 'Icons',
//     moduleName: 'icons',
//     iconType: 'material-icons-two-tone',
//     icon: 'eco',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Admin'],
//     submenu: [
//       {
//         path: '/icons/material',
//         title: 'Material Icons',
//         moduleName: 'icons',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/icons/font-awesome',
//         title: 'Font Awesome',
//         moduleName: 'icons',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//   {
//     path: '',
//     title: 'Authentication',
//     moduleName: 'authentication',
//     iconType: 'material-icons-two-tone',
//     icon: 'supervised_user_circle',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Admin'],
//     submenu: [
//       {
//         path: '/authentication/signin',
//         title: 'Sign In',
//         moduleName: 'authentication',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/authentication/signup',
//         title: 'Sign Up',
//         moduleName: 'authentication',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/authentication/forgot-password',
//         title: 'Forgot Password',
//         moduleName: 'authentication',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/authentication/locked',
//         title: 'Locked',
//         moduleName: 'authentication',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/authentication/page404',
//         title: '404 - Not Found',
//         moduleName: 'authentication',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/authentication/page500',
//         title: '500 - Server Error',
//         moduleName: 'authentication',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//   {
//     path: '',
//     title: 'Extra Pages',
//     moduleName: 'extra-pages',
//     iconType: 'material-icons-two-tone',
//     icon: 'description',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Admin'],
//     submenu: [
//       {
//         path: '/extra-pages/profile',
//         title: 'Profile',
//         moduleName: 'extra-pages',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/extra-pages/pricing',
//         title: 'Pricing',
//         moduleName: 'extra-pages',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/extra-pages/invoice',
//         title: 'Invoice',
//         moduleName: 'extra-pages',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/extra-pages/faqs',
//         title: 'Faqs',
//         moduleName: 'extra-pages',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/extra-pages/blank',
//         title: 'Blank Page',
//         moduleName: 'extra-pages',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//   {
//     path: '',
//     title: 'Maps',
//     moduleName: 'maps',
//     iconType: 'material-icons-two-tone',
//     icon: 'map',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Admin'],
//     submenu: [
//       {
//         path: '/maps/google',
//         title: 'Google Map',
//         moduleName: 'maps',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
//   {
//     path: '',
//     title: 'Multi level Menu',
//     moduleName: 'multilevel',
//     iconType: 'material-icons-two-tone',
//     icon: 'slideshow',
//     class: 'menu-toggle',
//     groupTitle: false,
//     badge: '',
//     badgeClass: '',
//     role: ['Admin'],
//     submenu: [
//       {
//         path: '/multilevel/first1',
//         title: 'First',
//         moduleName: 'multilevel',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//       {
//         path: '/',
//         title: 'Second',
//         moduleName: 'secondlevel',
//         iconType: '',
//         icon: '',
//         class: 'ml-sub-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [
//           {
//             path: '/multilevel/secondlevel/second1',
//             title: 'Second 1',
//             moduleName: 'secondlevel',
//             iconType: '',
//             icon: '',
//             class: 'ml-sub-sub-menu',
//             groupTitle: false,
//             badge: '',
//             badgeClass: '',
//             role: [''],
//             submenu: [],
//           },
//           {
//             path: '/multilevel/secondlevel/second2',
//             title: 'Second 2',
//             moduleName: 'secondlevel',
//             iconType: '',
//             icon: '',
//             class: 'ml-sub-sub-menu',
//             groupTitle: false,
//             badge: '',
//             badgeClass: '',
//             role: [''],
//             submenu: [],
//           },
//         ],
//       },
//       {
//         path: '/multilevel/first3',
//         title: 'Third',
//         moduleName: 'multilevel',
//         iconType: '',
//         icon: '',
//         class: 'ml-menu',
//         groupTitle: false,
//         badge: '',
//         badgeClass: '',
//         role: [''],
//         submenu: [],
//       },
//     ],
//   },
];
export const ROUTESRH: RouteInfo[] = [
  {
    path: '/admin/dashboard/main',
    title: 'MENUITEMS.HOME.TEXT',
    moduleName: 'dashboard',
    iconType: 'fas',
    icon: 'fa-home',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },
  {
    path: '',
    title: 'MENUITEMS.MAIN.TEXT',
    moduleName: '',
    iconType: '',
    icon: '',
    class: '',
    groupTitle: true,
    badge: '',
    badgeClass: '',
    role: ['ALL'],
    submenu: [],
  },
  {
    path: '',
    title: 'MENUITEMS.WORKERS.TEXT',
    moduleName: 'workers',
    iconType: 'fas',
    icon: 'fa-user',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN'],
    submenu: [
      {
        path: '/web/user/list/worker',
        title: 'MENUITEMS.WORKERSALL.TEXT',
        moduleName: 'workers',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
      },
      {
        path: '/web/user/add/worker',
        title: 'MENUITEMS.WORKERSADD.TEXT',
        moduleName: 'workers',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN'],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.MONTHLY_WAGES.TEXT',
    moduleName: 'payroll',
    iconType: 'fas',
    icon: 'fa-file-invoice-dollar',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN'],
    submenu: [
      {
        path: '/rh/payroll',
        title: 'PAYROLL.PAYROLLS.TEXT',
        moduleName: 'payroll',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN'],
        submenu: [],
      },
      {
        path: '/rh/payroll/add',
        title: 'PAYROLL.ADD.TEXT',
        moduleName: 'payroll',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN'],
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.CONTRACTS.TEXT',
    moduleName: 'contracts',
    iconType: 'fas',
    icon: 'fa-file-contract',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN'],
    submenu: [
      {
        path: '/rh/contract',
        title: 'MENUITEMS.CONTRACTSALL.TEXT',
        moduleName: 'contracts',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
      },
      {
        path: '/rh/contract/add',
        title: 'MENUITEMS.CONTRACTADD.TEXT',
        moduleName: 'contracts',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN'],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'RECRUITMENT.RECRUITMENT_STEPS.TEXT',
    moduleName: 'recruitment',
    iconType: 'fas',
    icon: 'fa-file-signature',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN'],
    submenu: [
      {
        path: '/rh/recruitment',
        title: 'RECRUITMENT.JOBS.TEXT',
        moduleName: 'contracts',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
      },
      {
        path: '/rh/recruitment/add',
        title: 'RECRUITMENT.ADD.TEXT',
        moduleName: 'contracts',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN'],
        submenu: [],
      },
    ],
  },
  // {
  //   path: '',
  //   title: 'MENUITEMS.PERMISSIONS.TEXT',
  //   moduleName: 'permissions',
  //   iconType: 'fas',
  //   icon: 'fa-sign-out-alt',
  //   class: 'menu-toggle',
  //   groupTitle: false,
  //   badge: '',
  //   badgeClass: '',
  //   role: ['ROLE_ADMIN'],
  //   submenu: [
  //     {
  //       path: '/rh/permission',
  //       title: 'MENUITEMS.PERMISSIONSALL.TEXT',
  //       moduleName: 'permissions',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: ['All'],
  //       submenu: [],
  //     },
  //     {
  //       path: '/rh/permission/add',
  //       title: 'MENUITEMS.PERMISSIONADD.TEXT',
  //       moduleName: 'permissions',
  //       iconType: '',
  //       icon: '',
  //       class: 'ml-menu',
  //       groupTitle: false,
  //       badge: '',
  //       badgeClass: '',
  //       role: ['ROLE_ADMIN'],
  //       submenu: [],
  //     },
  //   ],
  // },
  {
    path: '',
    title: 'MENUITEMS.VACATION.TEXT',
    moduleName: 'vacation',
    iconType: 'fas',
    icon: 'fa-calendar',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN'],
    submenu: [
      {
        path: '/rh/vacation',
        title: 'VACATION.MY_VACATIONS.TEXT',
        moduleName: 'vacation',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
      },
      {
        path: '/rh/request-vacation',
        title: 'VACATION.REQUEST_VACATIONS.TEXT',
        moduleName: 'vacation',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
      }
    ],
  },
  {
    path: '',
    title: 'MENUITEMS.ADVANCES.TEXT',
    moduleName: 'advances',
    iconType: 'fas',
    icon: 'fa-money-bill',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN'],
    submenu: [
      {
        path: '/rh/advance',
        title: 'MENUITEMS.ADVANCESALL.TEXT',
        moduleName: 'advances',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
      },
      {
        path: '/rh/advance/report',
        title: 'MENUITEMS.ADVANCES_REPORTS.TEXT',
        moduleName: 'advances',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'ALLOWANCE.ALLOWANCES.TEXT',
    moduleName: 'allowance',
    iconType: 'fas',
    icon: 'fa-donate',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN'],
    submenu: [
      {
        path: '/rh/allowance',
        title: 'ALLOWANCE.ALLOWANCES.TEXT',
        moduleName: 'allowance',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
      },
      {
        path: '/rh/allowance/report',
        title: 'ALLOWANCE.REPORTS.TEXT',
        moduleName: 'advances',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'COVENANT.COVENANTS.TEXT',
    moduleName: 'covenant',
    iconType: 'fas',
    icon: 'fa-praying-hands',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN'],
    submenu: [
      {
        path: '/rh/covenant',
        title: 'COVENANT.COVENANTS.TEXT',
        moduleName: 'covenant',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
      },
      {
        path: '/rh/covenant/report',
        title: 'COVENANT.REPORTS.TEXT',
        moduleName: 'covenant',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'TIME_CONTROL.LABEL.TEXT',
    moduleName: 'time-control',
    iconType: 'fas',
    icon: 'fa-clock',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN'],
    submenu: [
      {
        path: '/rh/time-control/day',
        title: 'TIME_CONTROL.BY_DAY.TEXT',
        moduleName: 'time-control',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN'],
        submenu: [],
      },
      {
        path: '/rh/time-control/worker',
        title: 'TIME_CONTROL.BY_WORKER.TEXT',
        moduleName: 'time-control',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN'],
        submenu: [],
      },
      {
        path: '/rh/time-control/working-hours',
        title: 'TIME_CONTROL.CALC_WORKING_HOURS.TEXT',
        moduleName: 'time-control',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN'],
        submenu: [],
      },
    ],
  },
  {
    path: '',
    title: 'RESIGNATION.RESIGNATIONS.TEXT',
    moduleName: 'resignation',
    iconType: 'fas',
    icon: 'fa-walking',
    class: 'menu-toggle',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [
      {
        path: '/rh/resignation',
        title: 'RESIGNATION.LABEL.TEXT',
        moduleName: 'resignation',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
      },
      {
        path: '/rh/request-resignation',
        title: 'RESIGNATION.REQUEST_RESIGNATIONS.TEXT',
        moduleName: 'resignation',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['All'],
        submenu: [],
      },
      {
        path: '/rh/dues-resignation',
        title: 'RESIGNATION.CALC_DUES.TEXT',
        moduleName: 'resignation',
        iconType: '',
        icon: '',
        class: 'ml-menu',
        groupTitle: false,
        badge: '',
        badgeClass: '',
        role: ['ROLE_ADMIN'],
        submenu: [],
      },
    ],
  },
  {
    path: '/rh/organizational',
    title: 'ORGANIZATIONAL.LABEL.TEXT',
    moduleName: 'settings',
    iconType: 'fas',
    icon: 'fa-sitemap',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN'],
    submenu: [],
  },
  {
    path: '/rh/holidays',
    title: 'MENUITEMS.SHEDULE.TEXT',
    moduleName: 'shedule',
    iconType: 'fas',
    icon: 'fa-calendar-times',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },
  {
    path: '/rh/notice',
    title: 'NOTICE.LABEL.TEXT',
    moduleName: 'notice',
    iconType: 'fas',
    icon: 'fa-bell',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['All'],
    submenu: [],
  },
  {
    path: '/rh/setting',
    title: 'MENUITEMS.SETTINGS.TEXT',
    moduleName: 'settings',
    iconType: 'fas',
    icon: 'fa-cog',
    class: '',
    groupTitle: false,
    badge: '',
    badgeClass: '',
    role: ['ROLE_ADMIN'],
    submenu: [],
  },
];
