import { Component, OnInit } from '@angular/core';
import {LocalStorage} from '../../shared/storage/storage.local';
@Component({
  selector: 'app-page-loader',
  templateUrl: './page-loader.component.html',
  styleUrls: ['./page-loader.component.sass']
})
export class PageLoaderComponent implements OnInit {
  msg: any;
  constructor(
    private localStorage: LocalStorage
  ) {}
  ngOnInit() {
    (this.localStorage.get('isRtl') === true) ? this.msg = ' أرجو الإنتظار... ' : this.msg = 'Please wait...';
  }
}
