import { Injectable } from '@angular/core';
import {ResourceService} from './resource.service';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService  extends ResourceService {

  constructor(public httpClient: HttpClient) {
    super(httpClient, '/notification');
  }

  generateNotificationLink(target, targetId, parentId) {
    switch (target) {
      case 'message':
        return ['/web/chat/home'];
      case 'plan':
        return ['/web/plan', targetId];
      case 'publication':
        return ['/web/study', parentId];
      case 'absence':
        return ['/web/session/absence/daily'];
      case 'session':
        return ['/web/session/schedule'];
      case 'assignment':
        return ['/web/study', parentId];
      case 'behavior':
        return ['/web/behavior'];
      case 'behaviorreport':
        return ['/web/behavior', targetId];
      case 'behaviorcertificat':
        return ['/web/behavior/certificate', targetId];
      case 'support':
        return ['/web/support/display', targetId];
      case 'group':
        return ['/web/chat/home'];
      case 'supportresponse':
        return ['/web/support/display', targetId];
      case 'plannerFile':
        return ['/web/study', parentId];
      case 'publicPublication':
        return ['/web/study-public'];
      default:
        return null;
    }
  }
}
