import { Injectable } from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from '../service/auth.service';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginGuard implements CanActivate {
  constructor(private auth: AuthService, private route: Router) { }
  canActivate() {
      if (this.auth.currentUserValue)
      {
        return true;
      }
      else{
        this.route.navigate(['/authentication/signin']);
        return false;
      }
    }

}
